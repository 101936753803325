import React from 'react';

import Layout from '../components/layout';
import Seo from "../components/seo"
import GraphicPortfolio from '../components/GraphicPortfolio';

const graphicdesign = () =>  ( 
  
  <Layout>
        <Seo title="Graphic Design" />
        <GraphicPortfolio />
   
    </Layout>
    
  
)

export default graphicdesign;
